<template>
  <div class="list-price">
    <div class="banner-top" v-if="data">
      <div class="banner-top__bg">
        <img :src="data.banner?.image" alt="" />
      </div>
      <div class="banner-top__content" data-aos="zoom-in">
        <div class="title">
          {{ data?.banner[locale].title }}
        </div>
        <router-link :to="'/member'">
          <div class="button">
            <img src="../assets/img/icons/arrow-right-black.svg" alt="" />
            <span @click="setMenuActive(4, true)">GIA NHẬP HỘI VIÊN</span>
          </div>
        </router-link>
      </div>
    </div>
    <category :afterLink="afterLink" :categoryBlue="true" />
    <div class="content-price">
      <div class="item" v-for="(item, index) in data.price_list" :key="index">
        <h3 data-aos="fade-up" data-aos-offset="50" data-aos-duration="5000">
          {{ item[locale].name }}
        </h3>
        <div data-aos="fade-up">
          <div>
            <img :src="item.background" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="sale-content" data-aos="fade-up">
      <h3 class="sale-content__title">Các Chương Trình Khuyến Mại</h3>
      <div class="sale-info" v-if="promos">
        <div class="sale-info__left" v-if="newsFist">
          <router-link
            :to="
              '/news/' +
              category.slug +
              '/' +
              newsFist.slug +
              '?id=' +
              newsFist.id
            "
          >
            <div class="item">
              <div class="item__bg">
                <img :src="newsFist.image" alt="" />
              </div>
              <div class="item__content">
                <h5 class="category-post">{{ category[locale].name }}</h5>
                <h3 class="title">
                  {{ newsFist[locale].title }}
                </h3>
              </div>
            </div>
          </router-link>
        </div>
        <div class="sale-info__right" v-if="newList && newList.length > 0">
          <div class="item" v-for="(item, index) in newList" :key="index">
            <router-link
              :to="
                '/news/' + category.slug + '/' + item.slug + '?id=' + item.id
              "
            >
              <div class="item__bg">
                <img :src="item.image" alt="" />
              </div>
              <div class="item__content">
                <h5 class="category-post">{{ category[locale]?.name }}</h5>
                <h3 class="title">
                  {{ item[locale].title }}
                </h3>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="view-more text-uppercase" @click="setMenuActive(3, 3)">
        <router-link :to="'/news/tin-khuyen-mai'">{{
          trans("frontend.button.view all")
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import CourseService from "@/services/CourseService";
import category from "@/components/category/category.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    category,
  },
  data() {
    return {
      data: "",
      promos: "",
      category: "",
      newsFist: "",
      newList: [],
      afterLink: {
        link: "/price-list",
        name: "Bảng Phí",
        main: 0,
        sub: 1,
      },
    };
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.dataPriceList();
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  methods: {
    setMenuActive(menu, sub) {
      this.$store.commit("setMenuActive", menu);
      this.$store.commit("setSubActive", sub);
    },
    async dataPriceList() {
      await CourseService.priceList()
        .then((resp) => {
          this.data = resp.data.data;
          this.promos = this.data?.promotions;
          this.category = this.promos?.category;
          this.newList = this.promos?.news;
          this.newsFist = this.newList?.length > 0 && this.newList[0];
          this.newList = this.newList.shift();
        })
        .catch(() => {});
    },
  },
};
</script>
